import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import theme from '../theme';
import { Typography, Box, List, ListItem, ListItemText, styled } from '@mui/material';
import { PiImageBroken } from "react-icons/pi";
import { GiFamilyTree } from "react-icons/gi";
import { CiDollar, CiCircleQuestion } from "react-icons/ci";
import { IoTrashOutline } from "react-icons/io5";
import GridItem from './GridItem';
import ContactForm from './ContactForm';
import Button from '@mui/material/Button';
import ThreeJsSnake from './NetworkAnimation';
import ChocolateCartoon from './ChocolateCartoon';
import { useTheme, useMediaQuery } from '@mui/material';
import ProductDemo from './demo/ProductDemo';

const FadeOverlay = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '25%',
    background: `linear-gradient(to right, rgba(255,255,255,0), ${theme.palette.background.default})`,
}));

const ImageContainer = styled(Box)({
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container>
                {/* Hero Section */}
                <Box
                    id="top"
                    sx={{
                        height: { xs: '90vh', md: '100vh' },
                        // width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="title" sx={{ fontWeight: 1000, width: { xs: "100%", md: '70%' } }}>
                        untangle the web
                    </Typography>
                    <Box sx={{ height: '20px' }}></Box>
                    <Typography variant="tagline" sx={{ width: { xs: "70%", md: '50%' } }}>
                        getting data from the web is hard, we create a layer of abstraction.
                    </Typography>
                </Box>

                <Box sx={{
                    height: { xs: 'auto', md: '70vh' },
                    overflow: { xs: 'auto', md: 'hidden' },
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    py: 4,
                }}>
                    <Typography variant="subtitle" sx={{ textAlign: 'left' }}>
                        the web is hard to understand
                    </Typography>
                    <Box sx={{ height: '5vh' }} />
                            <Box
                                sx={{
                                    padding: 0,
                                    textAlign: 'center',
                                }}
                            >
                                <Grid
                                    container

                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: { xs: 'column', md: 'row' },
                                spacing: {
                                    xs: 0, // No spacing for extra-small screens (e.g., iPhones)
                                    sm: 4, // Normal spacing for small screens and up
                                },
                                flexDirection: { xs: 'column', md: 'row' },
                            }}
                                >
                                    <Grid item xs={12} md={3}>
                                <GridItem icon="broken.png" text="Pages change their internal structure, making conventional automations fail." />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                <GridItem icon="money.png" text="Conventional tools require constant maintenance and expensive intervention." />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                <GridItem icon="mess.png" text="Traditional methods do not understand the meaning behind data being processed." />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                <GridItem icon="puzzle.png" text="Extracted data is often incomplete or inconsistent and requires manual correction." />
                                    </Grid>

                                </Grid>

                    </Box>
                </Box>


                < Box id="product" sx={{
                    height: { xs: 'auto', md: '80vh' },
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: { xs: 'flex-center', md: 'center' },
                    justifyContent: { xs: 'flex-center', md: 'center' },
                    textAlign: 'center',
                    py: { xs: 16, md: 4 },
                }}>
                        <Grid
                            container
                        sx={{
                            margin: 0, width: '100%', spacing: {
                                xs: 0, // No spacing for extra-small screens (e.g., iPhones)
                                sm: 4, // Normal spacing for small screens and up
                            },
                            flexDirection: { xs: 'column', md: 'row' },
                        }}
                        >

                            <Grid item xs={12} md={8}>
                                <Box
                                    sx={{
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >

                                    <ImageContainer>
                                        <Box
                                            component="img"
                                            src="./spreadsheet.png"
                                            alt="Spreadsheet"
                                            sx={{
                                                width: '100%',
                                                maxWidth: '100%', // Ensures it does not exceed the box width
                                                height: 'auto',
                                                maxHeight: '100vh', // Prevents it from exceeding viewport height
                                                display: 'block',
                                            }}
                                        />
                                        <FadeOverlay />
                                    </ImageContainer>
                                </Box>
                            <Box sx={{ height: { xs: '5vh', md: '0vh' } }}></Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                    // padding: 0,
                                        textAlign: 'left',
                                    }}
                                >
                                    <Typography variant="subtitle">
                                        shoco
                                    </Typography>
                                    <Box sx={{ height: '10px' }}></Box>
                                    <Typography variant="body1">
                                        Vertical integration at shoco turns URLs into structured databases.
                                    </Typography>
                                    <Typography variant="body1">
                                    Data processing, transformation, and
                                    validation are automated and supported by artificial intelligence.
                                    </Typography>
                                <Box sx={{ height: '25px' }}></Box>
                                <Button variant="contained" color="primary" href="https://ickd5odxdxp.typeform.com/to/PFKQNcrM" // Set the URL
                                    target="_blank"      // Opens the link in a new tab
                                    rel="noopener noreferrer" sm={2}>
                                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                        Join beta
                                        </Typography>
                                    </Button>
                                </Box>
                            <Box sx={{ height: { xs: '5vh', md: '0vh' } }}></Box>
                            </Grid>
                    </Grid>
                </Box>

                <ProductDemo />

                {/* <Demo /> */}


                {/* Partners Section */}
                <Box id="support" sx={{
                    height: { xs: "auto", md: '50vh' },
                    // width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    py: 4,
                    maxWidth: "100%",
                    mx: "auto",
                }}>
                    <Typography variant="subtitle" style={{ textAlign: 'center' }}>
                        supported by
                    </Typography>
                    {/* <Box sx={{ height: '30px' }}></Box> */}
                    <Grid container sx={{ spacing: { xs: 2, md: 4 } }}>
                        <Grid item xs={6} md={6}>
                            <Box
                                sx={{
                                    borderRadius: 1,
                                    padding: 3,
                                    textAlign: 'center',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <a href="https://ai.ethz.ch" target="_blank"
                                    rel="noopener noreferrer">
                                <Box component="img" src="./ai-center-logo.png" alt="ETH AI Center" sx={{ maxWidth: { xs: "100%", md: '60%' }, maxHeight: '100%' }} />
                                </a>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Box
                                sx={{
                                    borderRadius: 1,
                                    padding: 3,
                                    textAlign: 'center',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <a href="https://talentkick.ch" target="_blank"
                                    rel="noopener noreferrer">
                                    <Box component="img" src="./talentkick-logo.png" alt="Talentkick" sx={{ maxWidth: { xs: "100%", md: '60%' }, maxHeight: '100%' }} />
                                </a>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                {/* Customers Section */}
                {/* <Box sx={{ py: 8 }}>
                    <Typography variant="h4" gutterBottom>
                        Our Customers
                    </Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={4}>
                            <Box
                                sx={{
                                    border: '1px solid #e0e0e0',
                                    borderRadius: 1,
                                    padding: 3,
                                    textAlign: 'center',
                                }}
                            >
                                <Typography variant="h6">Customer 1</Typography>
                                <Typography variant="body1">
                                    Description of Customer 1.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box
                                sx={{
                                    border: '1px solid #e0e0e0',
                                    borderRadius: 1,
                                    padding: 3,
                                    textAlign: 'center',
                                }}
                            >
                                <Typography variant="h6">Customer 2</Typography>
                                <Typography variant="body1">
                                    Description of Customer 2.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box
                                sx={{
                                    border: '1px solid #e0e0e0',
                                    borderRadius: 1,
                                    padding: 3,
                                    textAlign: 'center',
                                }}
                            >
                                <Typography variant="h6">Customer 3</Typography>
                                <Typography variant="body1">
                                    Description of Customer 3.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box> */}

                {/* Success Story Section */}
                {/* <Box sx={{ py: 8, textAlign: 'center' }}>
                    <Typography variant="h4" gutterBottom>
                        Success Story
                    </Typography>
                    <Typography variant="body1" sx={{ maxWidth: '800px', margin: '0 auto' }}>
                        Xoco helped transform a traditional company into a digital-first enterprise, increasing productivity by 300% and reducing operational costs by 50%. Our innovative approach and cutting-edge technology made it possible.
                    </Typography>
                </Box> */}
            </Container>
        </ThemeProvider>
    );
}

export default App;