import React, { useState } from 'react';
import { Box, Paper, TextField, Button, Typography } from '@mui/material';


const API_URL = 'https://api.shoco.io:27337/v1/demo';
// const API_URL = 'http://localhost:8086/v1/demo';

const FeedbackComponent = ({ taskId }) => {
    const [feedback, setFeedback] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async () => {
        if (feedback.trim() === '') {
            setError('Feedback cannot be empty');
            return;
        }

        setIsSubmitting(true);
        setError('');

        try {
            const response = await fetch(`${API_URL}/${taskId}/feedback`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    feedback: feedback,
                }),
            });

            if (!response.ok) {
                console.log(response);
                throw new Error('Failed to submit feedback');
            }

            setFeedback('');
            alert('Feedback submitted successfully!');
        } catch (error) {
            setError('Failed to submit feedback. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Box sx={{ width: "100%", height: "100%", p: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                Did we get it right?
            </Typography>
            <TextField
                fullWidth
                multiline
                rows={4}
                maxRows={8}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value.slice(0, 500))}
                placeholder="Tell us your thoughts."
                inputProps={{ maxLength: 500 }}
                error={!!error}
                helperText={error || `${feedback.length}/500 characters`}
                sx={{ mb: 2 }}
            />
            <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: { xs: '12px', md: '14px' } }}>
                By clicking Submit, you agree to our <a href="/terms">Terms of Service</a> and <a href="/policy">Privacy Policy</a>.
            </Typography>
            <Box sx={{ py: "5px" }} />
            <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={isSubmitting}
            >
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
                </Typography>
            </Button>
        </Box>
    );
};

export default FeedbackComponent;