import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import LandingPage from './components/LandingPage';
import TermsPage from './components/TermsPage';
import PolicyPage from './components/PolicyPage';
import theme from './theme';
import { Box, Container } from '@mui/material';

function App() {
  return (
    <Router>
      <div>
        <Header />
        {/* <div style={{ minHeight: '60px', backgroundColor: theme.backgroundColor }}></div> */}
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy" element={<PolicyPage />} />
        </Routes>
        <Footer />
      </div>
    </Router >
  );
}

export default App;
