import React, { useState, useEffect } from 'react';
import { Typography, Skeleton, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card, CardContent } from '@mui/material';
import FeedbackComponent from './FeedbackCard';

const AdaptiveJsonDisplay = ({ status, taskId, response }) => {
    const [errorMsg, setErrorMsg] = useState(null);

    useEffect(() => {
        if (status === "ERROR") {
            if (taskId === null || !response.error) {
                setErrorMsg("There was an issue processing your request. Are you complying with our usage policy?");
            } else {
                setErrorMsg(response.error);
            }
        } else {
            setErrorMsg(null);
        }
    }, [status, taskId, response]);

    if (status === "unknown") {
        return null;
    }

    if (["EXTRACTING", "EXTRACTED", "PROCESSING", "PROCESSED"].includes(status)) {
        return (
            <Card sx={{ width: "100%", m: 2, bgcolor: 'grey.300' }}>
                <CardContent>
                    <Skeleton variant="text" width="60%" />
                    <Skeleton variant="text" width="80%" />
                    <Skeleton variant="text" width="40%" />
                </CardContent>
            </Card>
        );
    }

    if (status === "ERROR") {
        return (
            <Box sx={{ width: "100%", m: 2 }}>
                <CardContent>
                    <Typography variant="body1" color="error" sx={{ fontWeight: "bold" }}>
                        {errorMsg}
                    </Typography>
                </CardContent>
                {/* <FeedbackComponent taskId={taskId} /> */}
            </Box>
        );
    }

    if (status === "DONE") {
        return (
            <Box sx={{ width: "100%", m: 2, alignContent: "center" }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 100 }} aria-label="json data table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }}><Typography variant="body1" sx={{ fontWeight: "bold", textAlign: 'right' }}>Field:</Typography></TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}><Typography variant="body1" sx={{ fontWeight: "bold" }}>Value</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(response).map(([key, value]) => (
                                <TableRow key={key}>
                                    <TableCell component="th" scope="row" sx={{ whiteSpace: 'nowrap', textAlign: 'right', fontWeight: "bold" }}>
                                        <Typography variant="body1">{key}:</Typography>
                                    </TableCell>
                                    <TableCell sx={{ wordBreak: 'break-word' }}>
                                        <Typography variant="body1">{typeof value === 'object' ? JSON.stringify(value, null, 2) : String(value)}</Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
        </TableContainer>
            <Box sx={{ height: "20px" }} />
                <FeedbackComponent taskId={taskId} />
        </Box>
        );
    }

    return null;
};

export default AdaptiveJsonDisplay;