import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
    return (
        <Box component="footer" sx={{ bgcolor: 'white', color: 'black', py: 6 }}>
            <Container maxWidth="md">
                <Grid container spacing={4}>
                    <Grid item xs={4} md={4}>
                        <Typography variant="h6" gutterBottom>
                            Jump To
                        </Typography>
                        <Box>
                            <Link href="/#top" color="inherit" display="block" sx={{ mb: 1 }}>Top</Link>
                            <Link href="/#product" color="inherit" display="block" sx={{ mb: 1 }}>Product</Link>
                        </Box>
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <Typography variant="h6" gutterBottom>
                            Legal
                        </Typography>
                        <Box>
                            <Link component={RouterLink} to="/terms" color="inherit" display="block" sx={{ mb: 1 }}>Terms and Conditions</Link>
                            <Link component={RouterLink} to="/privacy" color="inherit" display="block" sx={{ mb: 1 }}>Privacy Policy</Link>
                            {/* <Link component={RouterLink} to="/agb" color="inherit" display="block" sx={{ mb: 1 }}>AGB</Link> */}
                        </Box>
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <Typography variant="h6" gutterBottom>
                            Contact Us
                        </Typography>
                        <Typography variant="body2">Clausiusstr 16</Typography>
                        <Typography variant="body2">8006 Zurich</Typography>
                        {/* <Typography variant="body2">Phone: (123) 456-7890</Typography> */}
                        <Typography variant="body2">
                            <a href="mailto:info@shoco.io">
                                info@shoco.io
                            </a>
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={12} md={4}>
                        <Typography variant="h6" gutterBottom>
                            Follow Us
                        </Typography>
                        <Box>
                            <IconButton color="inherit" aria-label="Facebook">
                                <FacebookIcon />
                            </IconButton>
                            <IconButton color="inherit" aria-label="Twitter">
                                <TwitterIcon />
                            </IconButton>
                            <IconButton color="inherit" aria-label="LinkedIn">
                                <LinkedInIcon />
                            </IconButton>
                            <IconButton color="inherit" aria-label="Instagram">
                                <InstagramIcon />
                            </IconButton>
                        </Box>
                    </Grid> */}
                </Grid>
                <Box mt={5}>
                    <Typography variant="body2" align="center">
                        © {new Date().getFullYear()} Shoco. All rights reserved.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;