import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        background: {
            default: "#ffffff", // Light blue background
        },
        text: {
            primary: "#000000", // Dark grey text
        },
        button: {
            primary: "#000000", // Blue button
        },
        status: {
            primary: {
                main: '#0a0f9f',
                dark: '#107517', // Darker shade for hover
            },
            error: {
                main: '#ff0000',
                dark: '#cc0000', // Darker shade for hover
            },
            success: {
                main: '#11bd1c',
                dark: '#15961e', // Darker shade for hover
            },
            info: {
                main: '#17d2eb',
                dark: '#12a7bb', // Darker shade for hover
            },
            grey: {
                300: '#e0e0e0',
                400: '#bdbdbd',
            },
            custom: {
                black: '#000000',
                darkBlack: '#000000', // Same as black for hover
                extracting: '#03befc',
                darkExtracting: '#04a7de', // Darker shade for hover
                processing: '#1c24ff',
                darkProcessing: '#0e15cf', // Darker shade for hover
            },
        }
    },
    typography: {
        title: {
            fontSize: "96px",
            fontFamily: 'Roboto, "Helvetica Neue", Arial, sans-serif', //'"Times New Roman", Times, serif', // Font for titles
            fontWeight: "bold",
            letterSpacing: "-0.01em",
            lineHeight: 1.2,
            "@media (max-width:1200px)": {
                // Medium devices
                fontSize: "72px",
            },
            "@media (max-width:960px)": {
                // Small devices
                fontSize: "48px",
            },
            "@media (max-width:600px)": {
                // Extra-small devices
                fontSize: "48px",
            },
        },
        tagline: {
            fontSize: "24px",
            fontFamily:
                "-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu", // Font for text paragraphs
            "@media (max-width:1200px)": {
                // Medium devices
                fontSize: "24px",
            },
            "@media (max-width:960px)": {
                // Small devices
                fontSize: "24px",
            },
            "@media (max-width:600px)": {
                // Extra-small devices
                fontSize: "18px",
            },
        },
        subtitle: {
            fontSize: "48px",
            fontFamily:
                "-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu", // Font for text paragraphs
            fontWeight: "bold",
            "@media (max-width:1200px)": {
                // Medium devices
                fontSize: "36px",
            },
            "@media (max-width:960px)": {
                // Small devices
                fontSize: "24px",
            },
            "@media (max-width:600px)": {
                // Extra-small devices
                fontSize: "24px",
            },
        },
        body1: {
            fontSize: "18px",
            fontFamily:
                "-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu", // Font for text paragraphs
            "@media (max-width:1200px)": {
                // Medium devices
                fontSize: "18px",
            },
            "@media (max-width:960px)": {
                // Small devices
                fontSize: "18px",
            },
            "@media (max-width:600px)": {
                // Extra-small devices
                fontSize: "18px",
            },
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "15px", // Custom border radius
                    textTransform: "none", // Disable uppercase transformation
                },
                containedPrimary: {
                    backgroundColor: "#c038c2", // Black background for primary buttons
                    color: "#ffffff", // White text
                    // '&:hover': {
                    //     backgroundColor: '#6e8cf0', // Slightly lighter black on hover
                    // },
                },
                outlinedPrimary: {
                    borderColor: "#000000", // Black border for outlined primary buttons
                    color: "#000000", // Black text
                    "&:hover": {
                        backgroundColor: "#f5f5f5", // Light background on hover
                    },
                },
                textPrimary: {
                    color: "#000000", // Black text for text buttons
                    "&:hover": {
                        backgroundColor: "#eeeeee", // Light grey on hover
                    },
                },
            },
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

export default theme;