import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import theme from '../../theme';
import { Button } from '@mui/material';
import RoundedButton from '../Rounded';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';


const HideOnScroll = ({ children }) => {
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
};

const Header = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <HideOnScroll>
                <AppBar position="fixed" sx={{ backgroundColor: 'white', boxShadow: '0 0px 0px rgba(0,0,0,0.1)' }}>
                    <Container maxWidth="lg">
                        <Toolbar disableGutters>
                            <Box display="flex" alignItems="center">
                                {/* <IconButton edge="start" color="inherit" aria-label="logo">
                                <img src="/api/placeholder/32/32" alt="Logo" style={{ height: '32px', width: '32px' }} />
                            </IconButton> */}
                                <Link href="/" style={{ color: 'inherit', textDecoration: 'none' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/logo.png`}
                                            alt="Shoco Logo"
                                            style={{ height: '40px' }}
                                        />
                                    </div>
                                </Link>
                            </Box>
                            <Box sx={{ flexGrow: 1 }} />
                            <Typography variant="header" component="div">
                                <nav>
                                    {/* <Link href="#products" variant="header" sx={{ marginRight: 3, color: 'text.primary', textDecoration: 'none', fontSize: '1.2rem' }}>
                                    Product
                                </Link>
                                <Link href="#pricing" variant="header" sx={{ marginRight: 3, color: 'text.primary', textDecoration: 'none', fontSize: '1.2rem' }}>
                                    Pricing
                                </Link>
                                <Link href="#about" variant="header" sx={{ color: 'text.primary', textDecoration: 'none', fontSize: '1.2rem' }}>
                                    About
                                </Link> */}
                                    {/* <Button variant="contained" sx={{
                                        borderRadius: '50px',
                                        padding: '10px 20px',
                                        textTransform: 'none',
                                        backgroundColor: "#6e8cf0",
                                        fontWeight: 'bold',
                                    }}>
                                        <Typography variant="header">Try Now</Typography>
                                    </Button> */}
                                    <ThemeProvider theme={theme}>
                                        <Button variant="contained" color="primary" href="https://ickd5odxdxp.typeform.com/to/PFKQNcrM" // Set the URL
                                            target="_blank"      // Opens the link in a new tab
                                            rel="noopener noreferrer">
                                            <Typography variant="header" sx={{ fontWeight: "bold" }}>Join beta</Typography>
                                        </Button>
                                    </ThemeProvider>
                                </nav>
                            </Typography>
                        </Toolbar>
                    </Container>
                </AppBar>
            </HideOnScroll>
        </ThemeProvider >
    );
};

export default Header;