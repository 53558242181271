import React from 'react';
import { Typography, Box, List, ListItem, ListItemText, styled } from '@mui/material';

const GridItem = ({ icon, text }) => {
    return (
        <>
            <Box sx={{ height: '2vh' }}></Box>
            <Box component="img" src={icon} sx={{
                width: { xs: "15%", md: "25%" }, // Responsive width for different breakpoints
                height: 'auto', // Maintain aspect ratio
            }} />
            <Box sx={{ height: '2vh' }}></Box>
            <Typography variant="body1" sx={{
                maxWidth: { xs: '70%', sm: '70%', md: '70%' }, // Adjust max width based on screen size
                margin: '0 auto', // Centers the text container horizontally
                textAlign: 'center', // Centers the text content
            }}>
                {text}
            </Typography>
            <Box sx={{ height: '2vh' }}></Box>

        </>
    );
};

export default GridItem;