import React, { useState, useEffect, useCallback } from 'react';
import { Button, TextField, Select, MenuItem, IconButton, Box, Typography, Paper, Grid } from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import HoverTooltip from './HoverExplain';
import StatusIndicator from './StatusHook';
import AdaptiveJsonDisplay from './ResponseCard';

const validDataTypes = ['string', 'float', 'bool', 'datetime'];
const displayDataTypes = ['Text', 'Number', 'True/False', 'Date'];
const dataTypeMap = { 'Text': 'string', 'Number': 'float', 'Date': 'datetime', 'True/False': 'bool' };

const MAX_ATTRIBUTES = 5;



const ProductDemo = () => {
    const [url, setUrl] = useState('');
    const [attributes, setAttributes] = useState([{ name: '', datatype: '', description: '' }]);
    const [urlError, setUrlError] = useState('');
    const [taskId, setTaskId] = useState(null);
    const [response, setResponse] = useState(null);

    const [status, setStatus] = useState('unknown');

    const API_URL = 'https://api.shoco.io:27337/v1/demo';
    // const API_URL = 'http://localhost:8086/v1/demo';

    const [isWorking, setIsWorking] = useState(false);

    useEffect(() => {
        const checkApiStatus = async () => {
            try {
                const response = await fetch('https://api.shoco.io:27337/v1/ping');
                if (response.ok) {
                    setIsWorking(true);
                } else {
                    setIsWorking(false);
                }
            } catch (error) {
                console.error('Error checking API status:', error);
                setIsWorking(false);
            }
        };

        checkApiStatus();
    }, []);



    const connectWebSocket = useCallback(() => {
        if (!taskId) {
            console.log('No taskId provided, not connecting to WebSocket');
            return;
        }

        console.log(`Connecting to WebSocket with taskId: ${taskId}`);
        const ws = new WebSocket(`${API_URL}/ws`);

        ws.onopen = () => {
            console.log('WebSocket connected');
            ws.send(JSON.stringify({
                type: 'subscribe_executions',
                task_id: taskId
            }));
        };

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log('WebSocket message received:', data);
            if (data.event === 'execution_update' && data.execution.current_state) {
                console.log(`Updating status to: ${data.execution.current_state}`);
                setStatus(data.execution.current_state);
                setResponse(data.execution.final_output);
            }

        };

        ws.onclose = () => {
            console.log('WebSocket disconnected');
            setTimeout(connectWebSocket, 1000);
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        return () => {
            if (ws.readyState === WebSocket.OPEN) {
                ws.close();
            }
        };
    }, [taskId]);

    useEffect(() => {
        const cleanup = connectWebSocket();
        return cleanup;
    }, [connectWebSocket]);


    const handleUrlChange = (event) => {
        const input = event.target.value;
        setUrl(input);
        setUrlError(validateURL(input));
    };

    const validateURL = (input) => {
        if (!input) return '';

        // Add https:// if no protocol is specified
        const urlToCheck = input.match(/^https?:\/\//) ? input : `https://${input}`;

        // More permissive regular expression for URL validation
        const urlPattern = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})(\/[\w\.\-\?=&%/]*)*\/?$/i;

        if (!urlPattern.test(urlToCheck)) {
            return "Invalid URL format";
        }

        const prohibitedExtensions = /\.(pdf|exe|zip|rar)$/i;
        if (prohibitedExtensions.test(urlToCheck)) {
            return "URLs ending with .pdf, .exe, .zip, or .rar are not allowed";
        }

        try {
            new URL(urlToCheck);
            return "";
        } catch (error) {
            return "Invalid URL";
        }
    };

    const handleAttributeChange = (index, field, value) => {
        const newAttributes = [...attributes];
        newAttributes[index][field] = value;
        if (field === 'datatype') {
            newAttributes[index].datatype = dataTypeMap[value];
        }
        setAttributes(newAttributes);
    };

    const addAttribute = () => {
        if (attributes.length < MAX_ATTRIBUTES) {
            setAttributes([...attributes, { name: '', datatype: '', description: '' }]);
        }
    };

    const removeAttribute = (index) => {
        const newAttributes = attributes.filter((_, i) => i !== index);
        setAttributes(newAttributes);
    };

    const validateInputReady = () => {
        // Check URL
        if (!url || urlError) {
            return { isValid: false, error: 'Invalid URL' };
        }

        // Check attributes
        if (attributes.length < 1 || attributes.length > 5) {
            return { isValid: false, error: 'The number of attributes should be between 1 and 5' };
        }

        for (let i = 0; i < attributes.length; i++) {
            const { name, datatype, description } = attributes[i];

            if (!name || !datatype || !description) {
                return { isValid: false, error: `Field ${i + 1} is incomplete. You need a name, datatype and description` };
            }

            if (!validDataTypes.includes(datatype)) {
                return { isValid: false, error: `Invalid data type for field ${i + 1}` };
            }
        }

        // If all checks pass, return true
        return { isValid: true };
    };

    // backend action

    const handleSubmit = async () => {
        const { isValid, error } = validateInputReady();
        if (!isValid) {
            alert(error);
            return;
        }

        let modifiedUrl = url;
        if (!url.startsWith('https://')) {
            modifiedUrl = 'https://' + url.replace(/^(http:\/\/)?/, '');
        }
        try {
            setStatus('SENDING');
            console.log(JSON.stringify({
                url: url,
                pattern: attributes
            }));
            const response = await fetch(`${API_URL}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    url: modifiedUrl,
                    pattern: attributes
                })
            });
            if (!response.ok) {
                setStatus('ERROR');
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            if (!response) {
                setStatus('ERROR');
                throw new Error('No response received');
            }
            const data = await response.json();
            setTaskId(data.task_id)
            return data;
        } catch (error) {
            console.error('Error in handleSubmit:', error);
            setStatus('ERROR');
            return null;
        }
    };

    if (!isWorking) {
        return null;
    }

    return (
        <Paper elevation={3} sx={{ p: 3, borderRadius: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        // width: '100%', // Ensure the Box takes full width
                        gap: 2, // Add some space between the Typography and HoverTooltip
                    }}
                >
                    <Typography variant="h5" component="div" sx={{ fontWeight: "bold" }}>
                        Try the demo
                    </Typography>
                    {/* <HoverTooltip text="We slowed and limited this demo to save costs and prevent misuse. Enter the URL of the page that interests you. Add attributes of things you want to retrieve from that page." /> */}
                    <HoverTooltip text={
                        <div>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                Basic Use:
                            </Typography>
                            <Typography variant="body1">
                                Enter the URL of the page that interests you. Add attributes of things you want to retrieve from that page. Give the datatype and shortly describe in natural text the element you are interested in.
                                <br /><br />
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                Usage Policy:
                            </Typography>
                            <Typography variant="body1">
                                This product is not built to circumvent security measures or retrieve data from websites without consent.
                                The demo is throttled and has limited functionality to help prevent misuse and reduce costs. We further do not
                                allow the retrieval of data from websites that are not public, are malicious, or contain NSFW content.
                            </Typography>
                        </div>
                    } />
                </Box>
                <StatusIndicator status={status} />
            </Box>

            <Box sx={{ height: '20px' }} />
            <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
            >
                <TextField
                    label="URL"
                    variant="outlined"
                    value={url}
                    onChange={handleUrlChange}
                    size="small"
                    fullWidth
                    error={!!urlError}
                    helperText={urlError}
                />
                <Box sx={{ height: '5px' }} />

                {attributes.map((attr, index) => (
                    <Grid
                        container
                        key={index}
                        spacing={2}
                        alignItems="center"
                        sx={{ mb: 0.5 }}
                    >
                        <Grid item xs={8} md={3}>
                            <TextField
                                fullWidth
                                label="Name of field"
                                variant="outlined"
                                value={attr.name}
                                onChange={(e) => handleAttributeChange(index, 'name', e.target.value)}
                                size="small"
                                inputProps={{ maxLength: 32 }}
                            />
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Select
                                fullWidth
                                value={attr.dataType}
                                onChange={(e) => handleAttributeChange(index, 'datatype', e.target.value)}
                                displayEmpty
                                size="small"
                            >
                                <MenuItem value="" disabled>
                                    Data Type
                                </MenuItem>
                                {displayDataTypes.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Description in natural text"
                                variant="outlined"
                                value={attr.description}
                                onChange={(e) => {
                                    const newValue = e.target.value.slice(0, 128);
                                    handleAttributeChange(index, 'description', newValue);
                                }}
                                size="small"
                                inputProps={{
                                    maxLength: 128,
                                }}
                                multiline
                                minRows={1}
                                maxRows={3}
                            />
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <IconButton
                                onClick={() => removeAttribute(index)}
                                color="error"
                                size="small"
                            >
                                <RemoveIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
                <Grid container spacing={2} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                    <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 1 } }}>
                        <Typography variant="caption" sx={{ ml: 2 }}>
                            {MAX_ATTRIBUTES - attributes.length} of {MAX_ATTRIBUTES} attributes remaining
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 }, textAlign: { xs: 'left', md: 'right' } }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: { xs: '12px', md: '14px' } }}>
                            By clicking Analyze, you agree to our <a href="/terms">Terms of Service</a> and <a href="/policy">Privacy Policy</a>.
                        </Typography>
                    </Grid>
                </Grid>

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={addAttribute}
                        disabled={attributes.length >= MAX_ATTRIBUTES}
                    >
                        Add Attribute
                    </Button>
                    <Button variant="contained" onClick={handleSubmit} disabled={status !== "DONE" && status !== "READY" && status !== "ERROR" && status !== "unknown"}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            Analyze
                        </Typography>
                    </Button>
                </Box>
            </Box>
            <Box display="flex"
                justifyContent="center"
                alignItems="center">
                <AdaptiveJsonDisplay status={status} taskId={taskId} response={response} />
            </Box>
        </Paper>
    );
};

export default ProductDemo;